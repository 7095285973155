/*     General overwrite     */
body{
    color: $font-color;
    font-size: $font-size-base;
    font-family: 'Muli', Arial, sans-serif;
    .wrapper{
        min-height: 100vh;
        position: relative;

        &:after{
            display: table;
            content: " ";
            clear: both;
        }
    }
}
a{
  color: $info-color;

  &:hover, &:focus{
     color: $info-states-color;
     text-decoration: none;
  }
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{
    outline:0 !important;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus,
button:focus {
    outline : 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert,
.general-animation{
    @include transition($general-transition-time, $transition-linear);
}

.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.filter,
.btn-hover,
[data-toggle="collapse"] i{
    @include transition($fast-transition-time, linear);
}

.sidebar .nav a,
.sidebar .nav .collapse i,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .user .info .collapse .nav a > span,
.sidebar .logo a.logo-mini,
.sidebar .logo a.logo-normal,
.table > tbody > tr .td-actions .btn,
.caret{
    @include transition($fast-transition-time, $transition-ease-in);
}

.btn,
.pagination a{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa{
    text-align: center;
}
.fa-base{
    font-size: 1.25em !important;
}

.margin-top-small{
    margin-top: 10px;
}

.margin-top{
    margin-top: 50px;
}

.margin-left-small{
    margin-left: 20px;
}

.margin-left{
    margin-left: 50px;
}

hr{
    border-color: $medium-pale-bg;
}

.animation-transition-general{
    @include transition($general-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition($fast-transition-time, $transition-linear);
}
.animation-transition-ultra-fast{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}

a[data-toggle="collapse"][aria-expanded="true"] .caret,
.btn[data-toggle="collapse"][aria-expanded="true"] .caret,
a.dropdown-toggle[aria-expanded="true"] .caret{
    @include rotate-180();
}

.section-gray{
    background-color: #EEEEEE;
}
.section-white{
    background-color: #FFFFFF;
}
