html.overflow-y-hidden {
    overflow-y: hidden
}

.g-dialog-overlay {
    --overlay-bg: var(--g-dialog-overlay-bg, rgba(33, 33, 33, .9));
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background: var(--01729808);
    z-index: 200
}

.g-dialog-overlay--active {
    pointer-events: auto
}

.fade-enter-active {
    transition: all .3s ease-out
}

.fade-leave-active {
    transition: all .2s cubic-bezier(1, .5, .8, 1)
}

.fade-enter-from, .fade-leave-to {
    opacity: 0
}

.g-dialog-content {
    --content-bg: var(--g-dialog-content-bg, #fff);
    --content-border-radius: var(--g-dialog-content-border-radius, 4px);
    --content-shadow: var(--g-dialog-content-shadow, 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%));
    pointer-events: auto;
    overflow-y: auto;
    overflow-x:hidden;
    transition: .3s cubic-bezier(.25, .8, .25, 1);
    width: 100%;
    background: #252422;
    border-radius: var(--26986c3c)
}

@media (min-width: 768px) {
    .g-dialog-content {
        width: auto;
    }
}

.g-dialog-content:not(.g-dialog-content--depressed) {
    box-shadow: var(--content-shadow)
}

.g-dialog-content:not(.g-dialog-content--fullscreen) {
    max-height: 90%;
    margin: 2px
}

@media (min-width: 768px) {
    .g-dialog-content {
        width: auto;
    }
}

.g-dialog-content--scrollable {
    display: flex
}

.g-dialog-content--fullscreen {
    height: 90%;
    border-radius: 0
}

.g-dialog-frame {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.25, .8, .25, 1);
    width: 100%;
    outline: none;
    pointer-events: none;
    z-index: 201
}

.g-dialog-transition {
    opacity: 0
}

.g-dialog-transition-enter-active, .g-dialog-transition-leave-active {
    transition-timing-function: cubic-bezier(.25, .8, .25, 1);
    transition-duration: .2s
}

.g-dialog-transition-enter-from, .g-dialog-transition-leave-to {
    transform: scale(.5);
    opacity: 0
}
