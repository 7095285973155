@mixin sidebar-background-color($background-color, $font-color){
    &:after,
    &:before{
	    background-color: $background-color;
	}

    .user{
        // border-bottom: 1px solid rgba($font-color,.3);
        position: relative;
        z-index: 4;

        &:after{
            background-color: rgba($font-color, 0.3);
        }

        .info a{
            color: $font-color;
        }
    }

    .logo{
        &:before{
            background-color: rgba($font-color, 0.3);
        }
    }

    .logo{
        p{
            color: $font-color;
        }

        .simple-text{
            color: $font-color;
        }
    }

    .nav{
        li:not(.active){
            > a{
                color: $font-color;
            }
        }
        .divider{
            background-color: rgba($font-color,.2);
        }

    }

}

@mixin sidebar-active-color($font-color){
    .nav{
        li{
            &.active > a{
                color: $font-color;
                opacity: 1;
            }
        }
    }
}
