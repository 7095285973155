.w80 {
  width: 80% !important;
}


.jsoneditor {
  .json-editor-btn-edit, .json-editor-btn-edit {
    border-radius: 0px;
  }

  .card {
    margin-top: 45px;
    color: $dark-gray;
    background-color: transparent;

  }

  .tab-pane > h3 > .btn-group {
    display: block;
  }

  .flex-column {
    > .nav-item {
      width: 100%;
      border-bottom: 1px solid $default-color;
    }

    > .nav-item:first-child {
      border-top: 1px solid $default-color;
    }
  }

  .nav-pills {
    > li + li {
      margin-left: 0;
    }
    > li > a {
      border: 0px solid $default-color;
      border-radius: 0;
      color: $default-color;
      font-weight: $font-weight-bold;
      margin-left: -1px;
      padding: 10px 20px;

    }
    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus {
      background-color: $default-color;
      color: $white-color;
    }
    > li:first-child > a{
      border-radius: 0;
    }
    > li:last-child > a{
      border-radius: 0;
    }

  }
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
