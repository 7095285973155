.panel{
    background-color: $pale-bg;
    border: 0 none;
    border-radius: $border-radius-small;
    font-size: 18;
    font-weight: $font-weight-light;
    line-height: 1.6em;
    margin-top: 10px;
    padding: 7px 10px;
}
.panel-border{
    border: 1px solid $gray-input-outline;
}
.panel-default{
    .panel-heading {
        background-color: $pale-bg;
        border: 0 none;
        border-radius: $border-radius-small;
    }
    a{
        color: $font-color;
        &:hover,
        &:active,
        &:focus{
            color: $danger-color;
        } 
        .panel-title >i{
            float: right;
        }
    }
    a[aria-expanded="true"],
    a.expanded{
        .panel-title > i{
            @include rotate-180();
        }
    }
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: 0 none;
}
