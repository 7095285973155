.footer{
    background-attachment: fixed;
    position: relative;
    line-height: 20px;
    nav {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          font-weight: normal;
            li{
                    display: inline-block;
                    padding: 10px 15px;
                    margin: 15px 3px;
                    line-height: 20px;
                    text-align: center;
            }
            a:not(.btn){
                color: $font-color;
                display: block;
                margin-bottom: 3px;

                &:focus,
                &:hover{
                    color: $default-states-color;
                }
            }
        }
    }
    .copyright{
        color: $font-color;
        padding: 10px 15px;
        font-size: 14px;
        margin: 15px 3px;
        line-height: 20px;
        white-space: nowrap;
        text-align: center;
    }
    .heart{
        color: $danger-color;
    }
}

.footer-black{
    background-color: $black-footer-bg;
    color: $medium-gray;

    .links ul{
        a:not(.btn){
            color: $brown-font-color;
            &:hover,
            &:focus{
                color: $brown-font-color-hover;
            }
        }
    }
    .copyright {
        color: $font-color;
        ul > li{
            a:not(.btn){
                color: $font-color;
            }
        }
    }

    hr{
        border-color: $font-color;
    }
}
.footer-transparent{
    background-size: cover;
    position: relative;
    background-color: transparent;

    .container{
        z-index: 2;
        position: relative;
    }
    hr{
        border-color: $brown-font-color;
    }
    .copyright {
        color: $brown-font-color;
        ul > li a:not(.btn){
                color: $brown-font-color;
        }
    }
}
